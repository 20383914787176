import { ServicesResult } from '~/views/Services/components/ServicesResultsSection/ServicesResultsSection.view'

const DevopsResults: ServicesResult[] = [
  {
    title: '+ 20%',
    description: 'DevOps help to increase development efficiency',
  },
  {
    title: '+ 50%',
    description: 'You will get enhanced web app stability',
  },
  {
    title: '5',
    description: 'DevOps speeds up the deployment at times',
  },
  {
    title: '60%',
    description: 'Your operational costs will reduce',
  },
  {
    title: 'x2',
    description: 'DevOps significantly improves your web application security',
  },
]

export default DevopsResults
