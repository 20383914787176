import { FAQ } from '~/types/data-array'

const DevopsFAQ: FAQ[] = [
  {
    question: 'How long does it take to set up a complete DevOps pipeline?',
    answer:
      'The timeline for implementing a fully functional DevOps pipeline may differ based on project complexity. Setting up and configuring a DevOps pipeline usually takes a couple of weeks, depending on the scope and scale of the projects in line.',
  },
  {
    question: 'What are the cost implications of implementing DevOps services?',
    answer:
      'The specific cost implications would vary from service requirements to the size and complexity of the project and the ongoing support needs. We have flexible pricing models harboring different business needs.',
  },
  {
    question:
      'How is the cost of DevOps services calculated for different project sizes?',
    answer:
      'Codica applies an individual approach to the pricing of DevOps services, elaborating on each solution and involved costs in view of unique requirements and a project’s scale for every client.',
  },
  {
    question:
      'What ongoing support is provided after the initial DevOps setup?',
    answer:
      'We provide ongoing support and maintenance after the initial setup. This includes monitoring and logging, security management, application updates and patches, and general troubleshooting and maintenance.',
  },
  {
    question:
      'Can DevOps services be integrated with existing IT infrastructure?',
    answer:
      'Yes, DevOps services can be integrated with existing IT infrastructure, whether it’s on-premises or cloud-based.',
  },
]

export default DevopsFAQ
