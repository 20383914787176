import HeadSection from '~/components/blocks/HeadSection'
import { DataContentProps } from '~/types/helper'

import * as containerStyles from './ServicesResultsSection.module.scss'

export interface ServicesResult {
  title: string
  description: string
}

const ServicesResultsSection = ({
  dataContent,
}: DataContentProps<ServicesResult>) => (
  <div className="container container-md">
    <div className="row">
      <div className="col-12 col-lg-6">
        <HeadSection
          position="left"
          title="DevOps results that you will get"
          description="Companies that have adopted DevOps significantly improve business performance."
        />
      </div>

      <div className="col-12 col-lg-6">
        <div className="row">
          {dataContent.map(({ title, description }) => (
            <div className="col-12 col-md-6" key={title}>
              <div className={containerStyles.servicesResultsSection__card}>
                <div className={containerStyles.servicesResultsSection__title}>
                  {title}
                </div>
                <div
                  className={
                    containerStyles.servicesResultsSection__description
                  }
                >
                  {description}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
)

export default ServicesResultsSection
