// extracted by mini-css-extract-plugin
export var devopsAchievementsSection = "D_fL";
export var devopsBenefitSection = "D_fz";
export var devopsBusinessSection = "D_fx";
export var devopsDomainSection = "D_fH";
export var devopsExpertiseSection = "D_fG";
export var devopsProvideSection = "D_fB";
export var devopsResultsSection = "D_fC";
export var devopsSuccessStoriesSection = "D_fJ";
export var devopsTestimonialPostSection = "D_fD";
export var devopsToolkitSection = "D_fF";
export var devopsVideoReviewSection = "D_fM";
export var devopsWhyChooseSection = "D_fK";
export var devopsWhyNeedSection = "D_fy";