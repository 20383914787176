import { getImage } from 'gatsby-plugin-image'

import CompanyWhyHireBlock from '~/components/blocks/CompanyWhyHireBlock'
import FaqSection from '~/components/blocks/FAQ'
import HeadSection from '~/components/blocks/HeadSection'
import Testimonial from '~/components/blocks/TestimonialPost'
import CallToAction from '~/components/call-to-actions/CallToAction'
import ServicesIconSmallCard from '~/components/cards/ServicesIconSmallCard'
import ServicesIndustriesCard from '~/components/cards/ServicesIndustriesCard'
import ClientVideoQuotes from '~/components/items/ClientVideoQuotes'
import MainLayout from '~/components/layouts/MainLayout'
import PrimeContactFormSection from '~/components/sections/PrimeContactFormSection'
import PrimeSectionServices from '~/components/sections/PrimeSectionServices'
import SuccessStoriesSliderSection from '~/components/sections/SuccessStoriesSliderSection'
import SubtractBlock from '~/components/shared/SubtractBlock'
import StickyNavigation from '~/components/sticky/StickyNavigation'
import Anchors from '~/mock/services/devops/Anchors'
import DevopsBenefits from '~/mock/services/devops/DevopsBenefits'
import DevopsDevExpertise from '~/mock/services/devops/DevopsDevExpertise'
import DevopsFAQ from '~/mock/services/devops/DevopsFAQ'
import DevopsIndustries from '~/mock/services/devops/DevopsIndustries'
import DevopsResults from '~/mock/services/devops/DevopsResults'
import DevopsServices from '~/mock/services/devops/DevopsServices'
import DevopsToolkit from '~/mock/services/devops/DevopsToolkit'
import DevopsWhyChoose from '~/mock/services/devops/DevopsWhyChoose'
import { fileToImageLikeData } from '~/utils'
import AwardsServicesSection from '~/views/Services/components/AwardsServicesSection'
import ServicesDevExpertiseCompany from '~/views/Services/components/ServicesDevExpertiseCompany'
import ServicesDevopsWhyNeedSection from '~/views/Services/components/ServicesDevopsWhyNeedSection'
import ServicesResultsSection from '~/views/Services/components/ServicesResultsSection'
import ServicesSectionBeforeTestimonials from '~/views/Services/components/ServicesSectionBeforeTestimonials'
import ServicesToolkitSection from '~/views/Services/components/ServicesToolkitSection'
import useDevOpsStaticQuery from '~/views/Services/DevOps/useDevOpsStaticQuery'

import * as containerStyles from './DevOps.module.scss'

const ServicesDevOps = () => {
  const query = useDevOpsStaticQuery()

  const tailoredOne = getImage(fileToImageLikeData(query.tailoredOne))
  const tailoredTwo = getImage(fileToImageLikeData(query.tailoredTwo))
  const videoReviewImage = getImage(fileToImageLikeData(query.videoReviewImage))
  const quoteCodicaIvanPhoto = getImage(
    fileToImageLikeData(query.quoteCodicaIvanPhoto),
  )

  return (
    <MainLayout scrollTop>
      <PrimeSectionServices
        title="DevOps Services"
        description="Codica will optimize your software development and deployment with a reliable DevOps engineering team on board."
        breadcrumbSecondLevel="Services"
        breadcrumbSecondLevelPath="/services/"
        breadcrumbCurrent="DevOps Services"
        breadcrumbCurrentPath="/services/devops/"
      />

      <StickyNavigation anchors={Anchors} />

      <section className={containerStyles.devopsBusinessSection} id="business">
        <HeadSection
          position="center"
          title="Grow your business with our DevOps team!"
          description="Our DevOps team automates repetitive tasks to reduce costs and optimize time."
          titleLarge
          descriptionLarge
        />
        <ServicesSectionBeforeTestimonials
          imageOne={tailoredOne}
          imageTwo={tailoredTwo}
          content={[
            {
              text: 'We provide DevOps services, which enable us to support any service and web product’s life cycle. We follow all well-known international standards for developing and maintaining software products.',
            },
            {
              text: 'Our DevOps team is suitable for different business projects, whether new startups or existing projects. Using our DevOps as a Service approach, we can significantly improve web products and their performance.',
            },
          ]}
        />
      </section>

      <section className={containerStyles.devopsWhyNeedSection}>
        <HeadSection
          position="center"
          title="Why do you need DevOps as a Service?"
          description="Web businesses that have not yet adopted DevOps services are currently facing the following problems:"
          titleLarge
        />
        <ServicesDevopsWhyNeedSection />
      </section>

      <CallToAction
        title="Are long deployment cycles and bottlenecks slowing you down?"
        subtitle="With our DevOps services, you can deliver high-quality software faster."
        classNameForGA="servicesCTA_1"
        linkName="Contact us"
        link="/contacts/"
      />

      <section className={containerStyles.devopsBenefitSection} id="benefits">
        <HeadSection
          position="center"
          title="How will DevOps benefit your business?"
          titleLarge
        />
        <ServicesIconSmallCard dataContent={DevopsBenefits} />
      </section>

      <section className={containerStyles.devopsProvideSection} id="services">
        <HeadSection
          position="center"
          title="DevOps services we provide"
          description="Our DevOps specialists have an in-depth understanding of both operations’ and software development processes. Therefore, we will help you attain your business needs and make your software platform highly performed."
        />
        <ServicesIconSmallCard isBorder dataContent={DevopsServices} />
        <SubtractBlock isTop />
      </section>

      <section className={containerStyles.devopsResultsSection} id="results">
        <ServicesResultsSection dataContent={DevopsResults} />
      </section>

      <section className={containerStyles.devopsTestimonialPostSection}>
        <Testimonial
          image={quoteCodicaIvanPhoto}
          name="Ivan"
          position="DevOps Engineer at Codica"
          quote={[
            {
              text: '“Our DevOps experts shares an overall goal — availability, security, and general product quality are the responsibility of everyone’s job every day. What drives the team working in the DevOps as a Service world and me? It is the desire to assist people on their development journey and the desire for new challenges.”',
            },
          ]}
        />
      </section>

      <CallToAction
        title="Want to make your software quality and reliable?"
        subtitle="Our DevOps team will help you!"
        classNameForGA="servicesCTA_2"
        linkName="Talk to an expert"
        link="/contacts/"
      />

      <section className={containerStyles.devopsToolkitSection} id="toolkit">
        <HeadSection
          position="center"
          title="Our well-proven DevOps toolkit"
          description="You can rely on Codica as an experienced web development company and DevOps service provider. Over the years, we’ve curated a list of DevOps tools that help us build high-quality, engaging web products and help businesses scale. With our best DevOps tools, we will optimize development time and efficiency, make changes to the solution more cost-effective, and increase fault tolerance."
          descriptionLarge
        />
        <ServicesToolkitSection dataContent={DevopsToolkit} />
      </section>

      <section className={containerStyles.devopsExpertiseSection}>
        <HeadSection
          position="center"
          title="Codica is about the quality result and clients"
          description="With our experience of 9+ years and professionalism, our team succeeds in developing awesome quality web projects. We have successfully built over 50 profitable software products, and the same number of clients were satisfied with the outcomes. This is our company’s mission - to deliver reliable and attractive solutions that acquire users and improve the efficiency of your business."
          descriptionLarge
        />
        <ServicesDevExpertiseCompany dataContent={DevopsDevExpertise} />
      </section>

      <section className={containerStyles.devopsDomainSection} id="industries">
        <HeadSection
          position="center"
          title="Domain expertise you can rely on"
          description="Creating web projects for particular industries has allowed our team to grow in-house expertise which we share with our customers. We provide consultations on every stage of application development and build products following the best industry standards."
          descriptionLarge
        />
        <ServicesIndustriesCard dataContent={DevopsIndustries} />
      </section>

      <section
        className={containerStyles.devopsSuccessStoriesSection}
        id="testimonials"
      >
        <HeadSection position="center" title="Success stories" />
        <SuccessStoriesSliderSection btnName="View all our works" />
      </section>

      <section className={containerStyles.devopsWhyChooseSection}>
        <HeadSection
          position="center"
          title="Why should you build your application with Codica?"
        />
        <CompanyWhyHireBlock dataContent={DevopsWhyChoose} />
      </section>

      <section className={containerStyles.devopsAchievementsSection}>
        <HeadSection
          position="center"
          title="Our achievements"
          description="We are immensely thrilled to develop awesome projects. Independent ratings and research firms recognize our work."
        />

        <AwardsServicesSection />

        <div className={containerStyles.devopsVideoReviewSection}>
          <div className="container container-md">
            <ClientVideoQuotes
              name="Celso Prado"
              position="Manager at the Adventures Group"
              location="Australia"
              image={videoReviewImage}
              quote={[
                {
                  text: '“They are like my secret weapon. We are in verticals where market leaders have hundreds of in-house developers and multi-billion dollars in annual revenue. And despite all of that, our lean team, with the help from Codica, is growing its market share year after year.”',
                },
              ]}
              link="online-marketplace-for-caravans"
              videoLink="https://www.youtube.com/embed/K9aOABDeKPI"
            />
          </div>
        </div>
      </section>

      <section id="free-quote">
        <PrimeContactFormSection
          title="Let’s build innovative software together!"
          formInfoClassName="formInfo__services"
          customSalesInfo
          customSalesInfoStepFour="4. Our expert web app developers will analyze your requirements and suggest the best ways to bring your idea to life."
        />
      </section>

      <section id="faq">
        <FaqSection title="FAQ" dataContent={DevopsFAQ} />
      </section>
    </MainLayout>
  )
}

export default ServicesDevOps
