import { ExpertiseSectionMock } from '~/types/mock'

const DevopsBenefits: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_16',
    title: 'Help you optimize and reduce development costs',
    description:
      'Our seamless workflow for software delivery reduces maintenance costs. With our FinOps services, we can help you obtain the maximum value from your business.',
  },
  {
    icon: 'spriteIcon_15',
    title: 'Enhance development speed and performance',
    description:
      'Speed is everything. Web development teams that follow DevOps principles release projects faster, reducing time to market, and, besides, the solutions are more reliable.',
  },
  {
    icon: 'spriteIcon_3',
    title: 'Let the web development team be more productive',
    description:
      'Since DevOps as a Service practices facilitate some of the processes for the team, this positively influences the workers’ psychological state and efficiency.',
  },
  {
    icon: 'spriteIcon_34',
    title: 'Increase scalability and configurability of the system',
    description:
      'Our DevOps engineers use practices that allow fast scaling of your infrastructure resources according to traffic spikes and fluctuations.',
  },
  {
    icon: 'spriteIcon_68',
    title: 'Keep your web information protected',
    description:
      'Our DevOps experts use various data security measures according to data protection regulations. So, you will get a robust digital platform of great security.',
  },
  {
    icon: 'spriteIcon_23',
    title: 'Improve client and end-user experience',
    description:
      'Adopting DevOps helps you to stay ahead of competitors by delivering top-quality services faster to the clients or end-users.',
  },
]

export default DevopsBenefits
