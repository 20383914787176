import { ExpertiseSectionMock } from '~/types/mock'

const DevopsToolkit: ExpertiseSectionMock[] = [
  {
    icon: 'serviceToolkitIcon1',
    title: 'AWS',
  },
  {
    icon: 'serviceToolkitIcon2',
    title: 'Terraform',
  },
  {
    icon: 'serviceToolkitIcon3',
    title: 'Docker',
  },
  {
    icon: 'serviceToolkitIcon4',
    title: 'Sentry',
  },
  {
    icon: 'serviceToolkitIcon5',
    title: 'Linux',
  },
  {
    icon: 'serviceToolkitIcon6',
    title: 'Ansible',
  },
  {
    icon: 'serviceToolkitIcon7',
    title: 'Prometheus',
  },
  {
    icon: 'serviceToolkitIcon8',
    title: 'PagerDuty',
  },
  {
    icon: 'serviceToolkitIcon9',
    title: 'Grafana',
  },
  {
    icon: 'serviceToolkitIcon10',
    title: 'New Relic',
  },
  {
    icon: 'serviceToolkitIcon11',
    title: 'Gitlab CI/CD',
  },
  {
    icon: 'serviceToolkitIcon19',
    title: 'Packer',
  },
  {
    icon: 'serviceToolkitIcon20',
    title: 'Golang',
  },
]

export default DevopsToolkit
