import { ExpertiseSectionMock } from '~/types/mock'

const DevopsServices: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_92',
    title: 'Assessment and planning',
    description:
      'We as a quality DevOps service provider, create a DevOps Assessment roadmap by visualizing the desired state and identifying the traceable KPIs.',
  },
  {
    icon: 'spriteIcon_3',
    title: 'Setting up of CI/CD',
    description:
      'We apply the best CI/CD (continuous integration and continuous delivery) practices to allow faster release, increase app quality, and streamline engineering workflows.',
  },
  {
    icon: 'spriteIcon_6',
    title: 'Integrating into the cloud',
    description:
      'Our DevOps team provides cloud setup, optimization, cost management, and reliable solutions in the cloud.',
  },
  {
    icon: 'spriteIcon_1',
    title: 'Process automation',
    description:
      'With the DevOps as a Service automation practice, we can automate manual and repetitive tasks throughout the entire web development lifecycle.',
  },
  {
    icon: 'spriteIcon_42',
    title: 'Monitoring and logging',
    description:
      'By monitoring and logging, we track everything that happens in the app and the servers it runs on. Thus, we can fix issues a few seconds after they appear.',
  },
  {
    icon: 'spriteIcon_68',
    title: 'Security management',
    description:
      'We apply top DevOps as a Service security practices for each part of app and systems implementation. This significantly reduces the possibility of data breaches.',
  },
  {
    icon: 'spriteIcon_93',
    title: 'Product support',
    description:
      'At Codica, our experts always support the projects after their launch. Therefore, we keep the application code up to date.',
  },
]

export default DevopsServices
