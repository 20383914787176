import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'

import { PhotosQuery } from '~/types/graphql'
import { cx, fileToImageLikeData } from '~/utils'

import * as containerStyles from './ServicesDevopsWhyNeedSection.module.scss'

const ServicesDevopsWhyNeedSection = () => {
  const query = useStaticQuery<PhotosQuery>(graphql`
    query {
      image: file(relativePath: { eq: "services/devops/devops-why-need.png" }) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
    }
  `)

  const image = getImage(fileToImageLikeData(query.image))

  return (
    <div className="container container-md">
      <div className={cx('row', containerStyles.devopsWhyNeed__row)}>
        <div className="col-12 col-md-6 col-lg-4">
          <div className={containerStyles.devopsWhyNeed__card}>
            <div className={containerStyles.devopsWhyNeed__title}>
              A problematic deployment
            </div>
            <div className={containerStyles.devopsWhyNeed__description}>
              A chaotic and problematic production deployment can cause
              significant downtime at your business.
            </div>
          </div>

          <div className={containerStyles.devopsWhyNeed__card}>
            <div className={containerStyles.devopsWhyNeed__title}>
              The negative influence on web development efficiency
            </div>
            <div className={containerStyles.devopsWhyNeed__description}>
              The absence of the DevOps services negatively impact the
              performance of your operations and development.
            </div>
          </div>

          <div className={containerStyles.devopsWhyNeed__card}>
            <div className={containerStyles.devopsWhyNeed__title}>
              The systems become more fragile
            </div>
            <div className={containerStyles.devopsWhyNeed__description}>
              Each vital business system is more exposed to errors and
              downtimes.
            </div>
          </div>
        </div>

        <div className={cx('col-4', containerStyles.devopsWhyNeed__image)}>
          <GatsbyImage
            image={image as IGatsbyImageData}
            alt="Our personalized approach to AI application development services| Codica"
            title="Our personalized approach to AI application development services"
          />
        </div>

        <div className="col-12 col-md-6 col-lg-4">
          <div className={containerStyles.devopsWhyNeed__card}>
            <div className={containerStyles.devopsWhyNeed__title}>
              The chaos in operations and development
            </div>
            <div className={containerStyles.devopsWhyNeed__description}>
              If the IT organizations are structured without DevOps as a
              Service, their Dev, and IT Ops teams have conflicting interests.
            </div>
          </div>

          <div className={containerStyles.devopsWhyNeed__card}>
            <div className={containerStyles.devopsWhyNeed__title}>
              Everyone becomes a little bit busier
            </div>
            <div className={containerStyles.devopsWhyNeed__description}>
              Without using DevOps practices, the team of developers gets busy
              with work that can be performed automatically.
            </div>
          </div>

          <div className={containerStyles.devopsWhyNeed__card}>
            <div className={containerStyles.devopsWhyNeed__title}>
              Uncertainty about the development progress
            </div>
            <div className={containerStyles.devopsWhyNeed__description}>
              The absence of DevOps techniques hinders close interaction with a
              client.
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServicesDevopsWhyNeedSection
