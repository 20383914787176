import { graphql, useStaticQuery } from 'gatsby'

import { PhotosQuery } from '~/types/graphql'

const useDevOpsStaticQuery = () => {
  const query = useStaticQuery<PhotosQuery>(graphql`
    query {
      tailoredOne: file(
        relativePath: { eq: "company-photo/company-photo-50.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      tailoredTwo: file(
        relativePath: { eq: "company-photo/company-photo-51.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      videoReviewImage: file(
        relativePath: {
          eq: "avatars/clients/large-photo/client-tradeaboat.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      quoteCodicaIvanPhoto: file(
        relativePath: { eq: "avatars/Devops-Ivan.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
    }
  `)

  return query
}

export default useDevOpsStaticQuery
